import { fadeElement } from './Util.js';

const STANDARD_FADE_DURATION = 200;

export class LoadingSpinner {

    static elementIDGen = 0;

    constructor(message, container) {

      // Créer et insérer le CSS
      this.styleax6 = document.createElement('style');
      this.styleax6.textContent = `
      #watermarkloader {
        position: fixed;
        top: 50%;
        left: 44%;
        cursor: pointer;
        opacity: 0.6;
        pointer-events: all;
        z-index: 1000;
        /* background-color: #FFF; */
      }
        #watermarkloader svg {
          width: 180px;

        }
      `;

      // Créer le conteneur principal
      this.watermarkloader = document.createElement('div');
      this.watermarkloader.id = 'watermarkloader';
    this.watermarkloader.onclick = () => {
        window.open('https://ax6.ca', '_blank');
      };

      // Ajouter le SVG directement
      this.watermarkloader.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 200 800 400" fill="gray">
          <g>
            <g>
              <path d="M152.32,242.75l72.57,190.99h-58.3l-15.49-40.69l-34.01,85.02h124.5l30.67,80.16h-58.61l-10.93-28.54H96.45l-11.54,28.54
                H26L152.32,242.75z"/>
              <path d="M356.68,558.25h-60.12l76.22-154.25l-76.22-154.25h60.12l46.15,93.52l46.46-93.52h59.82L356.68,558.25z M454.45,447.72
                l54.66,110.53h-59.82l-25.2-50.71L454.45,447.72z"/>
            </g>
            <g>
              <path d="M775,450.36c0,72.66-59.41,106-117.54,106c-71.37,0-131.64-47.87-131.64-140.62c0-100.87,64.97-167.97,143.18-167.97
                c52.57,0,79.5,30.35,79.5,59.84c0,21.37-18.38,44.02-44.02,44.02c-21.37,0-38.9-17.52-38.9-38.89c0-21.37,16.67-36.33,38.9-36.33
                c21.37,0,7.26-20.51-26.93-20.51c-59.41,0-62.83,104.71-62.83,144.03v10.69c5.98-24.36,23.51-50.43,59.83-50.43
                C722,360.18,775,392.23,775,450.36z M691.66,453.35c0-79.07-21.37-82.92-33.77-82.92c-5.13,0-10.68,1.71-14.96,4.7
                c-24.79,14.96-28.21,43.17-28.21,80.35c0,74.8,17.1,96.17,41.03,96.17C669.01,551.66,691.66,545.67,691.66,453.35z"/>
            </g>
          </g>
        </svg>
      `;
      /*
      this.container.appendChild(this.styleax6);
      this.container.appendChild(this.watermarkloader);
      */

        this.taskIDGen = 0;
        this.elementID = LoadingSpinner.elementIDGen++;

        this.tasks = [];

        this.message = message || 'Loading...';
        this.container = container || document.body;

        this.spinnerContainerOuter = document.createElement('div');
        this.spinnerContainerOuter.className = `spinnerOuterContainer${this.elementID}`;
        this.spinnerContainerOuter.style.display = 'none';

        this.spinnerContainerPrimary = document.createElement('div');
        this.spinnerContainerPrimary.className = `spinnerContainerPrimary${this.elementID}`;
        this.spinnerPrimary = document.createElement('div');
        this.spinnerPrimary.classList.add(`spinner${this.elementID}`, `spinnerPrimary${this.elementID}`);
        this.messageContainerPrimary = document.createElement('div');
        this.messageContainerPrimary.classList.add(`messageContainer${this.elementID}`, `messageContainerPrimary${this.elementID}`);
        this.messageContainerPrimary.innerHTML = this.message;

        this.spinnerContainerMin = document.createElement('div');
        this.spinnerContainerMin.className = `spinnerContainerMin${this.elementID}`;
        this.spinnerMin = document.createElement('div');
        this.spinnerMin.classList.add(`spinner${this.elementID}`, `spinnerMin${this.elementID}`);
        this.messageContainerMin = document.createElement('div');
        this.messageContainerMin.classList.add(`messageContainer${this.elementID}`, `messageContainerMin${this.elementID}`);
        this.messageContainerMin.innerHTML = this.message;

        this.spinnerContainerPrimary.appendChild(this.spinnerPrimary);
        this.spinnerContainerPrimary.appendChild(this.messageContainerPrimary);
        this.spinnerContainerOuter.appendChild(this.spinnerContainerPrimary);

        this.spinnerContainerMin.appendChild(this.spinnerMin);
        this.spinnerContainerMin.appendChild(this.messageContainerMin);
        this.spinnerContainerOuter.appendChild(this.spinnerContainerMin);

        this.spinnerContainerOuter.appendChild(this.styleax6);
          this.spinnerContainerOuter.appendChild(this.watermarkloader);



        const style = document.createElement('style');
        style.innerHTML = `

            .spinnerOuterContainer${this.elementID} {
                width: 100%;
                height: 100%;
                margin: 0;
                top: 0;
                left: 0;
                position: absolute;
                pointer-events: none;
            }

            .messageContainer${this.elementID} {
                height: 18px;
                font-family: arial;
                font-size: 10pt;
                color: #ffffff;
                text-align: left;
                vertical-align: middle;
                text-transform: uppercase;
            }

            .spinner${this.elementID} {
                padding: 4px;
                background: #88a5c9;
                z-index:99999;

                aspect-ratio: 1;
                border-radius: 50%;
                --_m:
                    conic-gradient(#0000,#000),
                    linear-gradient(#000 0 0) content-box;
                -webkit-mask: var(--_m);
                    mask: var(--_m);
                -webkit-mask-composite: source-out;
                    mask-composite: subtract;
                box-sizing: border-box;
                animation: load 1s linear infinite;
            }

            .spinnerContainerPrimary${this.elementID} {
                z-index:99999;
                background-color: rgba(40, 40, 40, 0.55);
                /*border: #666666 1px solid;
                border-radius: 5px;*/
                padding-top: 10px;
                padding-bottom: 10px;
                margin: 0;
                position: absolute;
                top: 70%;

                /*transform: translate(-80px, -80px);*/
                width: 100%;
                pointer-events: auto;
            }

            .spinnerPrimary${this.elementID} {
                width: 50px;
                float: left;
                /*left: 50%;
                margin: 0 48.1%;*/
                margin: 0 10px;
            }

            .messageContainerPrimary${this.elementID} {
                padding-top: 18px;
                padding-right: 10px;
            }

            .spinnerContainerMin${this.elementID} {
                z-index:99999;
                background-color: rgba(128, 128, 128, 0.75);
                border: #666666 1px solid;
                border-radius: 5px;
                padding-top: 20px;
                padding-bottom: 15px;
                margin: 0;
                position: absolute;
                bottom: 50px;
                left: 50%;
                transform: translate(-50%, 0);
                display: flex;
                flex-direction: left;
                pointer-events: auto;
                min-width: 250px;
            }

            .messageContainerMin${this.elementID} {
                margin-right: 15px;
            }

            .spinnerMin${this.elementID} {
                width: 50px;
                height: 50px;
                margin-left: 15px;
                margin-right: 25px;
            }

            .messageContainerMin${this.elementID} {
                padding-top: 15px;
            }

            @keyframes load {
                to{transform: rotate(1turn)}
            }

        `;
        this.spinnerContainerOuter.appendChild(style);
        this.container.appendChild(this.spinnerContainerOuter);

        this.setMinimized(false, true);

        this.fadeTransitions = [];
    }

    addTask(message) {
        const newTask = {
            'message': message,
            'id': this.taskIDGen++
        };
        this.tasks.push(newTask);
        this.update();
        return newTask.id;
    }

    removeTask(id) {
        let index = 0;
        for (let task of this.tasks) {
            if (task.id === id) {
                this.tasks.splice(index, 1);
                break;
            }
            index++;
        }
        this.update();
    }

    removeAllTasks() {
        this.tasks = [];
        this.update();
    }

    setMessageForTask(id, message) {
        for (let task of this.tasks) {
            if (task.id === id) {
                task.message = message;
                break;
            }
        }
        this.update();
    }

    update() {
        if (this.tasks.length > 0) {
            this.show();
            this.setMessage(this.tasks[this.tasks.length - 1].message);
        } else {
            this.hide();
        }
    }

    show() {
        this.spinnerContainerOuter.style.display = 'block';
        this.visible = true;
    }

    hide() {
        this.spinnerContainerOuter.style.display = 'none';
        this.visible = false;
    }

    setContainer(container) {
        if (this.container) {
            this.container.removeChild(this.spinnerContainerOuter);
        }
        if (container) {
            this.container = container;
            this.container.appendChild(this.spinnerContainerOuter);
            this.spinnerContainerOuter.style.zIndex = this.container.style.zIndex + 1;
        }
    }

    setMinimized(minimized, instant) {
        const showHideSpinner = (element, show, instant, displayStyle, fadeTransitionsIndex) => {
            if (instant) {
                element.style.display = show ? displayStyle : 'none';
            } else {
                this.fadeTransitions[fadeTransitionsIndex] = fadeElement(element, !show, displayStyle, STANDARD_FADE_DURATION, () => {
                    this.fadeTransitions[fadeTransitionsIndex] = null;
                });
            }
        };
        showHideSpinner(this.spinnerContainerPrimary, !minimized, instant, 'block', 0);
        showHideSpinner(this.spinnerContainerMin, minimized, instant, 'flex', 1);
        this.minimized = minimized;
    }

    setMessage(msg) {
        this.messageContainerPrimary.innerHTML = msg;
        this.messageContainerMin.innerHTML = msg;
    }
}
